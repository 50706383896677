body {
    background: radial-gradient(circle at 50% 50%, #2C2A36 0%, rgba(0, 0, 0, 1) 100%);
    background-size: cover;
    overflow: auto; 
    color: white;
}

.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    height: 100%;
}

.content-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    max-width: 700px;
    padding: 5vw;
}


.app-logo {
    margin-bottom: 2rem;
    filter: drop-shadow(100px 100px 100px #0D071C12);
    border-radius: 20px;
}

.title-text {
    font-family: Manrope;
    font-size: 34px;
    font-weight: 800;
    line-height: 46px;
    letter-spacing: -1.5839999914169312px;
    text-align: center;
}


.description-div{
    margin-top: 2rem;
    text-align: center;
    line-height: 1.2em;
    max-height: 2.4em; 
    overflow: hidden;
    font-family: Manrope;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
}

.second-description-div {
    margin-top: 2rem;
    font-family: Manrope;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
}


.links-div {
    margin-top: 2rem;
    display: flex;
    gap: 30px;
    justify-content: center;
}

.links-div a {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
}

.footer {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.footer span {
    font-family: Manrope;
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #FFFFFF80;
}

/* Media query for phones */
@media only screen and (max-width: 600px) {

    body {
        overflow: auto;
        height: 100%;
        /* padding-bottom: 100%; */
    }

    .description-div {
        max-height: 4.8em;
    }

    .app-logo {
        width: 25vw!important;
    }

    .appstore-logo {
        width: 35vw!important;
    }

    .links-div a {
        font-size: 3vw;
    }
}

.title-text {
    font-family: "Inter", sans-serif;
    letter-spacing: 0.05rem;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.5rem;
}

.title-header {
    font-size: 2rem;
}


p.p1 {margin: 0.0px 0.0px 0.0px 0.0px; font: 13.0px 'Helvetica Neue'}
p.p2 {margin: 0.0px 0.0px 0.0px 0.0px; font: 13.0px 'Helvetica Neue'; min-height: 15.0px}